<template>
	<simple-loading
		:is-loading="isLoading"
		:is-load-failed="loadFail"
		:showLoadingCard="true"
		@reload="fetchDetail"
	>
		<div class="card p-2">
			<el-form v-loading="saveLoading">
				<el-descriptions
					direction="vertical"
					class="margin-top"
					title="Account Details"
					:column="3"
					border
				>
					<template slot="extra">
						<el-button
							type="primary"
							size="small"
							@click="saveDetail"
							>Save</el-button
						>
					</template>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-user"></i>
							User ID
						</template>
						{{ detail.userId }}
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-user"></i>
							Username
						</template>

						<div class="center">
							<avatar
								:username="detail.username"
								:size="24"
							></avatar>
							{{ detail.username }}
						</div>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-user"></i>
							Full Name
						</template>
						<el-input
							v-model="detail.fullName"
							placeholder="Please input fullname"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-message"></i>
							E-mail
						</template>
            <el-input
              v-model="detail.email"
              placeholder="Please input email"
            ></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-position"></i>
							Phone Area Code
						</template>
						<el-input
							v-model="detail.phoneAreaCode"
							placeholder="Please input phone area code"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-mobile-phone"></i>
							Phone Number
						</template>
						<el-input
							v-model="detail.phone"
							placeholder="Please input phone number"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-office-building"></i>
							Company
						</template>
						<el-input
							v-model="detail.company"
							placeholder="Please input company name"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-table-lamp"></i>
							Department
						</template>
						<el-input
							v-model="detail.department"
							placeholder="Please input department"
						></el-input>
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-view"></i>
							Account Enable
						</template>
						<el-switch
							v-model="detail.enable"
							active-text="Active"
							inactive-text="Inactive"
						>
						</el-switch>
					</el-descriptions-item>

					<!-- <el-descriptions-item>
						<template slot="label">
							<i class="el-icon-lock"></i>
							Account Locked
						</template>
						<el-switch
							v-model="detail.locked"
							active-text="Unlock"
							inactive-text="Locked"
						>
						</el-switch>
					</el-descriptions-item> -->

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-refresh-right"></i>
							Login Failed Attempts
						</template>
						{{ detail.failedAttempts }}
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-date"></i>
							Last Login
						</template>
						{{
							dayjs(detail.lastLoginAt)
								.local()
								.format("YYYY-MM-DD HH:mm:ss")
						}}
					</el-descriptions-item>

					<el-descriptions-item>
						<template slot="label">
							<i class="el-icon-date"></i>
							Created At
						</template>
						{{
							dayjs(detail.createdAt)
								.local()
								.format("YYYY-MM-DD HH:mm:ss")
						}}
					</el-descriptions-item>

					<el-descriptions-item :span="3">
						<template slot="label">
							<i class="el-icon-date"></i>
							Updated At
						</template>
						{{
							dayjs(detail.updatedAt)
								.local()
								.format("YYYY-MM-DD HH:mm:ss")
						}}
					</el-descriptions-item>

					<el-descriptions-item :span="4">
						<template slot="label">
							<i class="el-icon-date"></i>
							Permissions
						</template>
						<el-checkbox-group v-model="detail.permissions">
							<el-checkbox
								v-for="permission in wrapperPermissions"
								:label="permission.key"
								:key="permission.key"
								>{{ permission.label }}</el-checkbox
							>
						</el-checkbox-group>
					</el-descriptions-item>
				</el-descriptions>
			</el-form>
		</div>
	</simple-loading>
</template>

<script>
	import {
		computed,
		onMounted,
		ref,
		getCurrentInstance,
	} from "@vue/composition-api";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
	import sizeHook from "@common-components/sizeHook";
	import Avatar from "vue-avatar";
	import SimpleLoading from "@common-components/simple-loading/SimpleLoading.vue";
	import dayjs from "dayjs";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

	export default {
		components: {
			Avatar,
			SimpleLoading,
		},
		setup(props, ctx) {
			const userId = ctx.root.$router.currentRoute.params.userId;
			const [size, element] = sizeHook();
			const isLoading = ref(false);
			const loadFail = ref(false);
			const detail = ref(null);
			const permissions = ref([]);

			const saveLoading = ref(false);
			const saveLoadFail = ref(false);

			const { $toast } = getCurrentInstance().proxy;

			onMounted(() => {
				fetchDetail();
			});

			const fetchDetail = async () => {
				isLoading.value = true;
				loadFail.value = false;

				try {
					const accountRes = await fetcher(
						apis.accountDetail + "/" + userId,
						FetchMethod.GET
					);

					const permissionsRes = await fetcher(
						apis.permissionList,
						FetchMethod.GET
					);

					detail.value = accountRes;
					permissions.value = permissionsRes;
				} catch (e) {
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			};

			const wrapperPermissions = computed(() => {
				return permissions.value.map((permission) => {
					return {
						key: permission.id,
						label: permission.displayName,
					};
				});
			});

			const saveDetail = async () => {
				saveLoading.value = true;
				saveLoadFail.value = false;

				try {
					await fetcher(
						apis.updateAccountDetail,
						FetchMethod.POST,
						detail.value
					);

					$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							title: "Save Success",
							icon: "CheckIcon",
							variant: "success",
						},
					});
				} catch (e) {
					saveLoadFail.value = true;
				} finally {
					saveLoading.value = false;
				}
			};

			return {
				isLoading,
				loadFail,
				detail,
				wrapperPermissions,
				dayjs,

				fetchDetail,
				saveDetail,

				saveLoading,
				saveLoadFail,
			};
		},
	};
</script>


<style scoped>
	.center {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	.permission-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
